<template>
  <div
    class="ip-button-container"
    :class="{
      'ip-button-container_adaptive': adaptive,
      'ip-button-container_disabled': disabled,
    }"
  >
    <!-- Router Link -->
    <RouterLink v-if="to" :to="to" :class="className">
      <slot/>
    </RouterLink>

    <!-- Button -->
    <button
      :type="type"
      :class="className"
      :disabled="disabled"
      @click="$emit('click')"
      v-else
    >
      <slot/>
    </button>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'IPButton',

  emits: ['click'],

  props: {
    to: {
      type: [Object, String],
    },

    variant: {
      type: String,
      default: 'primary',
      validate(value) {
        return [
          'primary',
          'secondary',
          'outline',
          'light',
          'ghost',
        ].includes(value);
      },
    },

    type: {
      type: String,
      default: 'button',
      validate(value) {
        return [
          'button',
          'submit',
        ].includes(value);
      },
    },

    size: {
      type: String,
      default: 'm',
      validator(value) {
        return ['s', 'm', 'l'].includes(value);
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    adaptive: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const className = computed(() => {
      const styles = ['ip-button', `ip-button_${props.variant}`, `ip-button_${props.size}`];

      if (props.disabled) {
        styles.push('ip-button_disabled');
      }

      if (props.adaptive) {
        styles.push('ip-button_adaptive');
      }

      return styles;
    });

    return {
      className,
    };
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/fonts";

.ip-button-container {
  width: fit-content;

  .ip-button {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    user-select: none;
    cursor: pointer;
    border: none;

    &_primary {
      background: var(--color-root-main);
      color: var(--color-text-base-white);

      &:hover {
        background: var(--color-root-main-hover);
      }
    }

    &_secondary {
      background: var(--color-root-base-white);
      color: var(--color-root-main)
    }

    &_outline {
      background: var(--color-background-base-white);
      color: var(--color-text-black);
      border: 2px solid var(--color-line-main);

      &:hover {
        color: var(--color-text-main)
      }
    }

    &_light {
      background: var(--color-light-main);
      color: var(--color-text-main);

      &:hover {
        background: var(--color-light-main-hover);
      }
    }

    &_ghost {
      border: none;
      color: var(--color-text-main);
      background: transparent;
    }

    &_s {
      @include font_button_s;
      min-width: 80px;
      height: 32px;
      border-radius: 4px;
      padding: 0 8px;
    }

    &_m {
      @include font_button_m;
      min-width: 120px;
      height: 40px;
      border-radius: 8px;
      padding: 0 12px;
    }

    &_l {
      @include font_button_l;
      min-width: 140px;
      height: 48px;
      border-radius: 8px;
      padding: 0 12px;
    }
  }

  &_adaptive {
    width: 100%;

    .ip-button_adaptive {
      width: 100%;
    }
  }
}
</style>
