export default {
  namespaced: true,

  state: {
    authMessage: '',
    errorCode: null,
  },

  mutations: {
    authMessage(state, value) {
      state.authMessage = value;
    },

    errorCode(state, value) {
      state.errorCode = value;
    },
  },
};
